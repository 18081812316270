<template>
	<ForgotPasswordForm />
</template>

<script>
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n/index';
import ForgotPasswordForm from '@/components/auth/forms/ForgotPasswordForm.vue';

export default {
	name: 'ForgotPassword',
	components: {
		ForgotPasswordForm
	},
	setup () {
		const { t } = useI18n();
		useMeta({ title: t('pages.auth.forgotPassword.pageTitle', { appName: 'Clarity' }) });
	}
};
</script>
