import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import adminRoutes from '@/router/routes/admin';
import authRoutes from '@/router/routes/auth';
import NotFound from '@/pages/errors/NotFound';

const routes = [...adminRoutes, ...authRoutes];
const baseUrl = process.env.BASE_URL;

const options = {
	history: createWebHistory(baseUrl),
	routes: [
		...routes,
		{
			path: '/:pathMatch(.*)*',
			component: NotFound
		}
	]
};

const router = createRouter(options);

router.beforeEach((to, from, next) => {
	const isAuthenticated = store.getters['auth/authenticated'];
	const { isRequestAuth } = to.meta;

	if (
		to.name !== 'login' &&
		isRequestAuth &&
		!isAuthenticated
	) {
		next({ name: 'login' });
	} else {
		next();
	}
});

export default router;
