<template>
	<TwoFactorChallengeForm
		@submit="login"
	/>
</template>

<script>
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n/index';
import TwoFactorChallengeForm from '@/components/auth/forms/TwoFactorChallengeForm';

export default {
	name: 'TwoFactorChallenge',
	components: {
		TwoFactorChallengeForm
	},
	setup () {
		const { t } = useI18n();
		useMeta({ title: t('pages.auth.twoFactorChallenge.pageTitle', { appName: 'Clarity' }) });

		const store = useStore();

		const login = (credentials) => {
			store.dispatch('auth/login/two-factor', credentials);
		};

		return {
			login
		};
	}
};
</script>
