import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';

const debug = process.env.NODE_ENV !== 'production';

const persist = createPersistedState({
	key: 'clarity-state'
});

export default createStore({
	modules: {
		auth
	},
	strict: debug,
	plugins: [persist]
});
