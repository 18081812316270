import router from '../router';

const responseInterceptor = response => {
	return response;
};

const errorInterceptor = error => {
	const { status } = error.response;
	switch (status) {
		case 401:
			router.push({ name: 'login' });
			break;
		case 419:
		case 422:
		case 500:
		default:
			break;
	}

	return error.response;
};

export default axios => {
	axios.interceptors.response.use(responseInterceptor, errorInterceptor);
};
