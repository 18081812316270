import axios from 'axios';
import api from '@/services/api';
import { Promise } from 'core-js';
import router from '../../router';
import { baseForm } from '@/store/base/form';

const state = {
	authenticated: false,
	userProfile: null
};

const getters = {
	authenticated (state) {
		return state.authenticated;
	},

	userProfile (state) {
		return state.userProfile;
	}
};

const mutations = {
	SET_AUTHENTICATED (state, value) {
		state.authenticated = value;
	},

	SET_USER_PROFILE (state, value) {
		state.userProfile = value;
	},

	SET_LOGIN_FORM (state, value) {
		state.loginForm = value;
	}
};

const actions = {
	async logout ({ commit }) {
		return new Promise((resolve, reject) => {
			api.auth.logout().then(() => {
				localStorage.removeItem('access_token');

				router.replace({ name: 'login' });

				commit('SET_USER_PROFILE', null);
				commit('SET_AUTHENTICATED', false);

				resolve(true);
			});
		});
	},

	async getUserProfile ({ commit }) {
		return new Promise((resolve, reject) => {
			api.auth
				.getUserProfile()
				.then(response => {
					commit('SET_USER_PROFILE', response.data);
					commit('SET_AUTHENTICATED', true);

					resolve(response);
				})
				.catch(error => {
					commit('SET_USER_PROFILE', null);
					commit('SET_AUTHENTICATED', false);

					reject(error);
				});
		});
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
	modules: {
		login: baseForm({
			url: 'api/auth/login',
			method: 'post',
			onSuccess: ({ dispatch }, response) => {
				const { data: responseData } = response.data;
				const { token } = responseData;

				if (token) {
					localStorage.setItem('access_token', token);
					axios.defaults.headers.common.Authorization = `Bearer ${token}`;
				}

				if (responseData.password_reset) {
					router.replace({
						name: 'reset-password'
					});
					return;
				}

				dispatch('auth/getUserProfile').then(() => {
					if (router.currentRoute.value.name === 'login') {
						router.replace({ name: 'dashboard' });
					}
				});
			}
		}),

		forgotPassword: baseForm({
			url: 'api/password/forgot',
			method: 'post',
			onSuccess: ({ _ }, response) => {
				router.replace({
					name: 'forgot-password.token'
				});
			}
		}),

		forgotPasswordToken: baseForm({
			url: 'api/password/forgot/token',
			method: 'post',
			onSuccess: ({ _ }, response) => {
				const { token } = response?.data?.data || {};
				if (token) {
					localStorage.setItem('access_token', token);
					axios.defaults.headers.common.Authorization = `Bearer ${token}`;
				}

				router.replace({
					name: 'reset-password'
				});
			}
		}),

		resetPassword: baseForm({
			url: 'api/password/reset',
			method: 'post',
			onSuccess: ({ dispatch }, response) => {
				const { token } = response?.data?.data || {};
				if (token) {
					localStorage.setItem('access_token', token);
					axios.defaults.headers.common.Authorization = `Bearer ${token}`;
				}

				dispatch('auth/getUserProfile').then(() => {
					router.replace({ name: 'dashboard' });
				});
			}
		})
	}
};
