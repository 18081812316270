import isEqual from 'lodash.isequal';
import * as api from '@/services/apis/base'; // can replace with axios

/**
 * This will be the base form helper.
 *
 * @params {string} form key
 */
export const baseForm = ({
	url = '',
	method = 'get',
	onSuccess = () => {},
	onError = () => {}
}) => ({
	namespaced: true,
	state: {
		default: {},
		data: {},
		message: '',
		errors: [],
		isDirty: false,
		progressing: false
	},
	getters: {
		default: state => state.default,
		data: state => state.data,
		message: state => state.message,
		errors: state => {
			const errors = {};

			for (const [key, value] of Object.entries(state.errors ?? {})) {
				errors[key] = typeof value === 'string' ? value : value[0];
			}

			return errors || {};
		},
		isDirty: state => state.isDirty,
		progressing: state => state.progressing
	},
	mutations: {
		CLEAR_ERRORS (state) {
			state.errors = [];
			state.message = '';
		},
		RESET_ALL (state) {
			state.data = state.default;
		},
		RESET (state, key) {
			state.data[key] = state.default[key];
		},
		SET_PROGRESSING: (state, value) => {
			state.progressing = value;
		},
		SET_IS_DIRTY: (state, value) => {
			state.isDirty = value;
		},
		SET_DEFAULT_DATA: (state, value) => {
			state.default = value;
		},
		SET_DATA: (state, value) => {
			state.data = {
				...state.data,
				...value
			};
			state.isDirty = !isEqual(state.data, state.default);
		},
		SET_ERRORS: (state, value) => {
			state.errors = value;
		},
		SET_MESSAGE: (state, value) => {
			state.message = value;
		}
	},
	actions: {
		clearErrors ({ commit }) {
			commit('CLEAR_ERRORS');
		},

		resetAll ({ commit }) {
			commit('RESET_ALL');
		},

		reset ({ commit }, key) {
			commit('RESET', key);
		},

		setDefaultData ({ commit }, data) {
			commit('SET_DEFAULT_DATA', data);
			commit('SET_DATA', data);
		},

		updateData ({ commit, state }, event) {
			commit('CLEAR_ERRORS');
			if (event && event.target) {
				const { target } = event;
				const { name, type, checked } = target;
				let { value } = target;

				if (!name) {
					console.warn("This input don't have name attr.");
				}

				// handle checkbox
				if (type === 'checkbox') {
					value = checked;
				}

				const newData = {
					...state.data,
					[name]: value
				};

				commit('SET_DATA', newData);
			}
		},

		async submit ({ commit, state, dispatch }) {
			commit('CLEAR_ERRORS', []);
			commit('SET_PROGRESSING', true);

			return new Promise((resolve, reject) => {
				api[method](url, state.data)
					.then(response => {
						if ([200, 201].includes(response.status)) {
							onSuccess(this, response);
						} else {
							commit('SET_ERRORS', response.data.errors || {});
							commit('SET_MESSAGE', response.data.message || '');

							onError(this, response);
						}

						resolve(response);
					})
					.catch(error => {
						reject(error);
					})
					.finally(() => commit('SET_PROGRESSING', false));
			});
		}
	}
});
