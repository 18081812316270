import MainLayout from '@/layouts/MainLayout';

import User from '@/pages/User';
import InProgress from '@/pages/InProgress';

export default [
	{
		path: '/',
		component: MainLayout,
		children: [
			{
				path: 'dashboard',
				name: 'dashboard',
				component: InProgress,
				meta: {
					isRequestAuth: true
				}
			},

			{
				path: 'user',
				name: 'user',
				component: User,
				isRequestAuth: true
			}
		]
	}
];
