import AuthLayout from '@/layouts/AuthLayout';

import {
	Login,
	ForgotPassword,
	ForgotPasswordToken,
	ResetPassword,
	TwoFactorChallenge
} from '@/pages';

export default [
	{
		path: '/',
		component: AuthLayout,
		children: [
			{
				path: '/login',
				name: 'login',
				component: Login
			},
			{
				path: '/logout',
				name: 'logout',
				component: Login
			},

			{
				path: 'forgot-password',
				name: 'forgot-password',
				component: ForgotPassword
			},

			{
				path: 'forgot-password/token',
				name: 'forgot-password.token',
				component: ForgotPasswordToken
			},

			{
				path: 'reset-password',
				name: 'reset-password',
				component: ResetPassword
			},

			{
				path: 'two-factor-challenge',
				name: 'two-factor-challenge',
				component: TwoFactorChallenge
			}
		]
	}
];
