import { computed } from 'vue';
import store from '../../store';

export default (namespace, data) => {
	store.dispatch(`${namespace}/setDefaultData`, data);
	store.dispatch(`${namespace}/updateData`, data);

	const form = {
		default: computed(() => store.getters[`${namespace}/default`]),

		data: computed(() => store.getters[`${namespace}/data`]),

		message: computed(() => store.getters[`${namespace}/message`]),

		errors: computed(() => store.getters[`${namespace}/errors`]),

		isDirty: computed(() => store.getters[`${namespace}/isDirty`]),

		progressing: computed(() => store.getters[`${namespace}/progressing`]),

		clearErrors () {
			store.dispatch(`${namespace}/clearErrors`);
		},
		resetAll () {
			store.dispatch(`${namespace}/resetAll`);
		},
		reset (key) {
			store.dispatch(`${namespace}/reset`, key);
		},
		setDefaultData (data) {
			store.dispatch(`${namespace}/setDefaultData`, data);
			store.dispatch(`${namespace}/updateData`, data);
		},
		updateData (data) {
			store.dispatch(`${namespace}/updateData`, data);
		},
		submit () {
			return store.dispatch(`${namespace}/submit`);
		}
	};

	return form;
};
