import { createI18n } from 'vue-i18n/index';

function loadLocaleMessages () {
	const locales = require.context('../lang', true, /[A-Za-z0-9-_,\s]+\.json$/i);

	const messages = {};
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);

		if (matched && matched.length > 1) {
			const locale = matched[1];

			const path = key.split('/');
			const lang = path[1];

			if (!messages[lang]) {
				messages[lang] = {};
			}

			messages[lang][locale] = locales(key);
		}
	});
	return messages;
}

export default createI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || 'en',
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	messages: loadLocaleMessages()
});
