import { createServer, Model, Response } from 'miragejs';

export function makeServer ({ environment = 'development' } = {}) {
	const server = createServer({
		environment,

		models: {
			user: Model
		},

		seeds (server) {
			server.create('user', { name: 'Bob' });
			server.create('user', { name: 'Alice' });
		},

		routes () {
			this.namespace = '';

			this.get('/sanctum/csrf-cookie', () => {
				return new Response(200, {});
			});

			this.post('/api/auth/login', (_, { requestBody }) => {
				//  const body = JSON.parse(requestBody);

				const headers = {};
				const responseBody = {
					errors: {
						email: ['The email field is required.']
					}
				};

				return new Response(200, headers, responseBody);
			});
		}
	});

	return server;
}
