<template>
	<ResetPasswordForm />
</template>

<script>
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n/index';
import ResetPasswordForm from '@/components/auth/forms/ResetPasswordForm.vue';

export default {
	name: 'ResetPassword',
	components: {
		ResetPasswordForm
	},
	setup () {
		const { t } = useI18n();
		useMeta({ title: t('pages.auth.resetPassword.pageTitle', { appName: 'Clarity' }) });
	}
};
</script>
