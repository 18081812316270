<template>
    <h1>{{ pageTitle }}</h1>
	<h3>{{ $t('user.name', { name: 'John Doe' }) }}</h3>

	<a @click="test('ja')">JA</a>
	<a @click="test('en')">EN</a>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n/index';

export default {
	setup () {
		const { t, locale } = useI18n({ useScope: 'global' });
		locale.value = 'en';

		const pageTitle = ref(t('user.page-title'));

		const test = (lang) => {
			locale.value = lang;
		};

		return {
			pageTitle,
			test
		};
	}
};
</script>
