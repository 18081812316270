import axios from 'axios';
import interceptors from '../api-interceptors';

axios.defaults.withCredentials = true;

if (process.env.NODE_ENV !== 'development-api-mock') {
	axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

	const accessToken = localStorage.getItem('access_token');

	if (accessToken) {
		axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
	}
}

interceptors(axios);

export const get = (url, params) => {
	return new Promise((resolve, reject) => {
		axios
			.get(url, { params })
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				resolve(error.response);
			});
	});
};

export const post = (url, data) => {
	return new Promise((resolve) => {
		axios
			.post(url, data)
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				resolve(error.response);
			});
	});
};

export const put = (url, data) => {
	return new Promise((resolve, reject) => {
		axios
			.put(url, data)
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				resolve(error.response);
			});
	});
};

export const destroy = (url, data) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(url, data)
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				resolve(error.response);
			});
	});
};
